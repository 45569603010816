<template>
  <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
    <template v-slot:title>Detail Mapping SP-SH-Price</template>
    <template v-slot:content>
      <bs-error-message :errors="errorMessages"/>

      <div class="mb-3">
        <label for="timeRange" class="form-label">No. Contract</label>
        <bs-plain-text v-model="model.contractNumber"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Sold to</label>
        <bs-plain-text v-model="model.soldToPartyLabel"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Ship to</label>
        <bs-plain-text v-model="model.shipToPartyLabel"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Location</label>
        <bs-plain-text v-model="model.locationCode"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Base Price Profile</label>
        <bs-plain-text :model-value="`${model.basePriceProfileName}`"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Price</label>
        <bs-plain-text :model-value="`${model.basePriceQuantity}`"/>
      </div>


      <div class="mb-3">
        <label for="code" class="form-label">Diffrential</label>
        <bs-plain-text v-model="model.diffrential"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Fee</label>
        <bs-plain-text v-model="model.feeProfileName"/>
      </div>

      <div class="mb-3">
        <label for="timeRange" class="form-label">Sales Currency</label>
        <bs-plain-text v-model="model.currencyCode"/>
      </div>

      <div class="mb-3">
        <label for="timeRange" class="form-label">Sales Unit</label>
        <bs-plain-text v-model="model.unitCode"/>
      </div>

      <div class="mb-3">
        <label for="timeRange" class="form-label">Terms Of Payment</label>
        <bs-plain-text v-model="model.termsOfPaymentName"/>
      </div>

    </template>
    <template v-slot:footer>
      <button class="btn btn-sm btn-primary" @click="editData"><i class="bi bi-pencil-square"></i>
        Edit
      </button>
      <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
    </template>
  </bs-popup>
</template>

<script>
import SpshService from "@/services/spsh-service";
// import SecurityService from "@/services/security-service.js";

export default {
  components: {},
  data: function () {
    return {
      id: null,
      model: SpshService.default(),
      errorMessages: null
    }
  },
  mounted() {
    let id = this.$route.params.id;
    this.id = id;
    this.loadData();
  },
  methods: {
    async loadData() {
      let response = await SpshService.getObject(this.id);
      this.model = response.data;
      if (response.status) {
        this.errorMessages = null;
      } else {
        this.errorMessages = response.errorMessages;
      }
    },
    onPopupClosed() {
      this.$router.push('/spsh');
    },
    close() {
      this.$refs.form.closePopup();
    },
    editData() {
      this.$refs.form.closePopup();
      this.$router.push(`/spsh/edit/${this.id}`);
    }
  }
}
</script>